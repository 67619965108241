var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-link',{staticClass:"brand-logo "},[_c('tally-sale-logo',{staticClass:"d-none d-md-inline"}),_c('h2',{staticClass:"brand-text text-primary ml-1 d-none d-md-inline"},[_vm._v(" Tally Sale ")])],1),_c('b-col',{staticClass:"d-none d-lg-flex align-items-center p-5",attrs:{"lg":"8"}},[_c('div',{staticClass:"w-100 d-lg-flex align-items-center justify-content-center px-5"},[_c('b-img',{attrs:{"fluid":"","src":_vm.imgUrl,"alt":"Register V2"}})],1)]),_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-2 p-lg-5",attrs:{"lg":"4"}},[_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"sm":"8","md":"6","lg":"12"}},[_c('b-card-title',{staticClass:"font-weight-bold mb-1",attrs:{"title-tag":"h2"}},[_c('span',{staticClass:"brand-text text-primary d-block d-md-none",staticStyle:{"font-weight":"bold !important"}},[_c('tally-sale-logo',{staticClass:"d-inline d-md-none mr-1",staticStyle:{"width":"42px"}}),_vm._v("Tally Sale ")],1),_vm._v(" Quick & Easy Sale 🚀 ")]),_c('validation-observer',{ref:"registerForm"},[_c('b-form',{staticClass:"auth-register-form mt-2"},[_c('b-form-group',{attrs:{"label":_vm.$t('Business') + ' ' + _vm.$t('Name'),"label-for":"branch_name"}},[_c('validation-provider',{attrs:{"name":"Branch","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"branch_name","name":"branch_name","state":errors.length > 0 ? false:null,"placeholder":"Enter your business name"},model:{value:(_vm.branchName),callback:function ($$v) {_vm.branchName=$$v},expression:"branchName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":_vm.$t('Business') + ' ' + _vm.$t('Phone'),"label-for":"phone"}},[_c('validation-provider',{attrs:{"name":"Phone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"phone","name":"phone","state":errors.length > 0 ? false:null,"placeholder":"Enter your valid phone number"},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"topyenoh"},[_c('b-form-input',{attrs:{"id":"topyenoh","name":"topyenoh"},model:{value:(_vm.topyenoh),callback:function ($$v) {_vm.topyenoh=$$v},expression:"topyenoh"}})],1),_c('b-form-group',{attrs:{"label":_vm.$t('Business') + ' ' + _vm.$t('Address'),"label-for":"address"}},[_c('validation-provider',{attrs:{"name":"Address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"address","name":"address","state":errors.length > 0 ? false:null,"placeholder":"Enter your business address"},model:{value:(_vm.address),callback:function ($$v) {_vm.address=$$v},expression:"address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":'Your ' + _vm.$t('Name'),"label-for":"address"}},[_c('validation-provider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"employee_name","name":"employee_name","state":errors.length > 0 ? false:null,"placeholder":"Enter your Name"},model:{value:(_vm.employeeName),callback:function ($$v) {_vm.employeeName=$$v},expression:"employeeName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Email","label-for":"register-email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-email","name":"register-email","state":errors.length > 0 ? false:null,"placeholder":"Enter your valid email address"},model:{value:(_vm.userEmail),callback:function ($$v) {_vm.userEmail=$$v},expression:"userEmail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-for":"register-password","label":"Password"}},[_c('validation-provider',{attrs:{"name":"Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"register-password","type":_vm.passwordFieldType,"state":errors.length > 0 ? false:null,"name":"register-password","placeholder":"············"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('b-form-checkbox',{attrs:{"id":"register-privacy-policy","name":"checkbox-1"},model:{value:(_vm.agreePolicy),callback:function ($$v) {_vm.agreePolicy=$$v},expression:"agreePolicy"}},[_vm._v(" I agree to "),_c('b-link',{attrs:{"target":"_blank","href":"https://tallysale.com/terms-of-service"}},[_vm._v(" Terms of Service ")])],1)],1),_c('b-button',{attrs:{"variant":"primary","block":"","disabled":!_vm.agreePolicy},on:{"click":_vm.register}},[_vm._v(" Sign up ")])],1)],1),_c('p',{staticClass:"text-center mt-2"},[_c('span',[_vm._v("Already Registered?")]),_c('b-link',{attrs:{"to":{name:'login'}}},[_c('span',[_vm._v(" Sign In")])])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }