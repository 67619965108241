<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo ">
        <tally-sale-logo class="d-none d-md-inline" />
        <h2 class="brand-text text-primary ml-1 d-none d-md-inline">
          Tally Sale
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Register V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Register-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            <span
              class="brand-text text-primary d-block d-md-none"
              style="font-weight:bold !important;"
            >
              <tally-sale-logo
                style="width:42px;"
                class="d-inline d-md-none mr-1"
              />Tally Sale
            </span>
            Quick & Easy Sale 🚀
          </b-card-title>

          <!-- form -->
          <validation-observer ref="registerForm">
            <b-form class="auth-register-form mt-2">

              <!-- Business Name -->
              <b-form-group
                :label="$t('Business') + ' ' + $t('Name')"
                label-for="branch_name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Branch"
                  rules="required"
                >
                  <b-form-input
                    id="branch_name"
                    v-model="branchName"
                    name="branch_name"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Enter your business name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- Phone -->
              <b-form-group
                :label="$t('Business') + ' ' + $t('Phone')"
                label-for="phone"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Phone"
                  rules="required"
                >
                  <b-form-input
                    id="phone"
                    v-model="phone"
                    name="phone"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Enter your valid phone number"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group class="topyenoh">
                <b-form-input
                  id="topyenoh"
                  v-model="topyenoh"
                  name="topyenoh"
                />
              </b-form-group>

              <!-- Address -->
              <b-form-group
                :label="$t('Business') + ' ' + $t('Address')"
                label-for="address"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Address"
                  rules="required"
                >
                  <b-form-input
                    id="address"
                    v-model="address"
                    name="address"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Enter your business address"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- Employee Name  -->
              <b-form-group
                :label="'Your ' + $t('Name')"
                label-for="address"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Name"
                  rules="required"
                >
                  <b-form-input
                    id="employee_name"
                    v-model="employeeName"
                    name="employee_name"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Enter your Name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- email -->
              <b-form-group
                label="Email"
                label-for="register-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="register-email"
                    v-model="userEmail"
                    name="register-email"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Enter your valid email address"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- password -->
              <b-form-group
                label-for="register-password"
                label="Password"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="register-password"
                      v-model="password"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      :state="errors.length > 0 ? false:null"
                      name="register-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group>
                <b-form-checkbox
                  id="register-privacy-policy"
                  v-model="agreePolicy"
                  name="checkbox-1"
                >
                  I agree to
                  <b-link
                    target="_blank"
                    href="https://tallysale.com/terms-of-service"
                  >
                    Terms of Service
                  </b-link>
                </b-form-checkbox>
              </b-form-group>

              <b-button
                variant="primary"
                block
                :disabled="!agreePolicy"
                @click="register"
              >
                Sign up
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <span>Already Registered?</span>
            <b-link :to="{name:'login'}">
              <span>&nbsp;Sign In</span>
            </b-link>
          </p>

        </b-col>
      </b-col>
    <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import TallySaleLogo from '@/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BButton, BForm, BFormCheckbox, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BImg, BCardTitle,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    TallySaleLogo,
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      branchName: '',
      phone: '',
      address: '',
      agreePolicy: true,
      employeeName: '',
      userEmail: '',
      password: '',
      topyenoh: '',
      sideImg: require('@/assets/images/pages/register-v2.svg'),
      // validation
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/register-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    register() {
      if (this.topyenoh) {
        return
      }

      this.$refs.registerForm.validate().then(success => {
        if (success) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Submitting new business registration form',
              autoHideDelay: '5000',
              text: 'Please wait a moment ...',
              icon: 'LogInIcon',
              variant: 'primary',
            },
          })

          useJwt.register({
            branch_name: this.branchName,
            phone: this.phone,
            address: this.address,
            employee_name: this.employeeName,
            email: this.userEmail,
            password: this.password,
            topyenoh: this.topyenoh,
          })
            .then(response => {
              if (response) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Success',
                    text: 'We have created your Account. Please check your email and follow instructions to activate the account. Thanks',
                    icon: 'AlertTriangleIcon',
                    variant: 'success',
                  },
                })
                this.$router.push({ name: 'login' })
              }
            })
            .catch(error => {
              this.$refs.registerForm.setErrors(error.response.data)
              this.errorMessage = error.response.statusText
              if (error.response.status === 422) {
                if (error.response.data.email) {
                  this.errorMessage = 'Email already exists.'
                }
              }

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Error',
                  text: this.errorMessage,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
        } else {
          // do something
        }
      })
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
